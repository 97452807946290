/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

	// Use this variable to set up the common and page specific functions. If you
	// rename this variable, you will also need to rename the namespace below.
	var Sage = {
		// All pages
		'common': {
			init: function() {
				$('.flexslider').flexslider();
				$('.mbYTP_wrapper').css('display', 'none');

				$('.tlt').textillate({
					// enable looping
  					loop: true,
  					in: {
					    // set the effect name
					    effect: 'flipInX',

					    // set the delay factor applied to each consecutive character
					    delayScale: 0.5,

					    // set the delay between each character
					    delay: 0,

					    // set to true to animate all the characters at the same time
					    sync: true,

					    // randomize the character sequence 
					    // (note that shuffle doesn't make sense with sync = true)
					    shuffle: false,

					    // reverse the character sequence 
					    // (note that reverse doesn't make sense with sync = true)
					    reverse: false,

					    // callback that executes once the animation has finished
					    callback: function () {}
				  	},

				  	// out animation settings.
					out: {
					    effect: 'flipOutX',
					    delayScale: 0.5,
					    delay: 0,
					    sync: true,
					    shuffle: false,
					    reverse: false,
					    callback: function () {}
					},
				});
				// JavaScript to be fired on all pages
				var navFixedTop = false;
				$(window).scroll(function () {
					var navbarOffsetTop = 0;
					if($(window).width() > 767)
					{
						navbarOffsetTop = 156;
						if (!navFixedTop && $(window).scrollTop() > navbarOffsetTop) {
							$('.banner').addClass('navbar-mini');
							$('#search').addClass('nav-mini');
							navFixedTop = true;
						}
						else if(navFixedTop && $(window).scrollTop() <= navbarOffsetTop){
							$('.banner').removeClass('navbar-mini');
							$('#search').removeClass('nav-mini');
							navFixedTop = false;
						}

					}
				});

				var hasHash = window.location.hash !== '' ? window.location.hash : window.location.href;
				if (hasHash.indexOf('%23') !== -1)
				{
					hasHash = '#' + ( hasHash.indexOf( '%23' ) !== -1 ? hasHash.substr( hasHash.indexOf( '%23' ) + 3 ) : window.location.hash );
				}
				else if (hasHash.indexOf('#') === -1)
				{
					hasHash = '';
				}

				if(hasHash){
					// event.preventDefault();
					var finished_projects = $('#finished').data('projects');

					finished_projects = finished_projects.split(',');
					hash_compare_string = hasHash.replace('#', '');
					var hash = hasHash;

					var bool_var = false;
					$.each( finished_projects, function( i, val ) {
						if(val === hash_compare_string)
						{
							$('.nav-tabs a[href="#finished"]').tab('show');
							setTimeout(function(){
								var offsetPixels = $(hash).offset().top - 126;
								$("html, body").animate({ scrollTop: offsetPixels  }, "slow");
							}, 150);
							bool_var = true;
						}
					});

					setTimeout(function(){
						temphash = hash.replace('#', '');
						$('#collapse' + temphash).toggleClass('collapse');
						console.log( '#collapse' + temphash);
					}, 150);

					if(bool_var === false)
					{
						$('.nav-tabs a[href="#ongoing"]').tab('show');
						var offsetPixels = $(hash).offset().top - 126;
						$("html, body").animate({ scrollTop: offsetPixels  }, "slow");
					}

				}

				$('.goto-project').on('click', function(){
					
				});

			},
			finalize: function() {
				// JavaScript to be fired on all pages, after page specific JS is fired
				/*$('#cff .cff-photo img').load(function() {
					setTimeout(function() {
						var currentWidth = $(this).width();
						$(this).css('height', currentWidth+"px");
						console.log('init');
					}, 1000);
				});
				$(window).resize(function() {
					var images = $('#cff .cff-photo img');
					currentWidth = images.width();
					images.css('height', currentWidth+"px");
				});*/
			}
		},
		// Home page
		'home': {
			init: function() {
				// JavaScript to be fired on the home page
			},
			finalize: function() {
				// JavaScript to be fired on the home page, after the init JS
			}
		},
		// About us page, note the change from about-us to about_us.
		'projekt': {
			init: function() {
				// JavaScript to be fired on the about us page

			}
		}
	};

	// The routing fires all common scripts, followed by the page specific scripts.
	// Add additional events for more control over timing e.g. a finalize event
	var UTIL = {
		fire: function(func, funcname, args) {
			var fire;
			var namespace = Sage;
			funcname = (funcname === undefined) ? 'init' : funcname;
			fire = func !== '';
			fire = fire && namespace[func];
			fire = fire && typeof namespace[func][funcname] === 'function';

			if (fire) {
				namespace[func][funcname](args);
			}
		},
		loadEvents: function() {
			// Fire common init JS
			UTIL.fire('common');

			// Fire page-specific init JS, and then finalize JS
			$.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
				UTIL.fire(classnm);
				UTIL.fire(classnm, 'finalize');
			});

			// Fire common finalize JS
			UTIL.fire('common', 'finalize');
		}
	};

	// Load Events
	$(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
